import './Sixth.css'
import React, { useRef } from 'react'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper'

export default ()=>{
    const {t,i18n} = useTranslation()
    const swiperRef = useRef()
    const items = [{
            'url':'kalsel.jpg',
            'name':'Zaenal Helmi',
            'job':<div>Karyawan Swasta<br/>Kab. Banjar, Kalimantan Selatan<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaRegStar/></div>,
            'desc':'“Cukup puas tetapi ingin agar limit pinjaman nya bisa terus naik karena beliau sudah sering pinjam di aplikasi namun limitnya tidak naik lagi.”',
            'date':'17 April 2023',
            'delay': '200'
        },
        {
            'url':'banten.jpeg',
            'name':'Acep Ahmad Fauzi',
            'job':<div>Karyawan Swasta<br/>Tangerang, Banten<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></div>,
            'desc':'“Cukup puas dengan aplikasi, tidak ada komentar atau kritik.”',
            'date':'28 Desember 2022',
            'delay': '400'
        },
        {
            'url':'bogor.jpg',
            'name':'Dewi Alawiyah',
            'job':<div>Ibu Rumah Tangga<br/>Kab. Bogor, Jawa Barat<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaRegStar/></div>,
            'desc':'“Lancar dan tidak ada kendala, cepat cair dana nya dan puas sama produknya.”',
            'date':'23 September 2022',
            'delay': '600'
        },
        {
            'url':'mie_laksa.jpg',
            'name':'Ali Hazrudin',
            'job':<div>Karyawan Swasta<br/>Tangerang, Banten<br/><br/></div>,
            'star': <div className='flex'><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></div>,
            'desc':'“Pinjaman cepat dan tidak ribet. Kritik nya tenornya terlalu singkat hanya per 2 minggu sekali, ingin dapat pilihan tenor cicilan nya per satu bulan sekali. Dan kritik juga terkait dengan ceklis biru saat pemilihan limit pinjaman yang tidak bisa dihilangkan, yaitu di limit 2.6jt.”',
            'date':'31 Maret 2023',
            'delay': '800'
        }
    ]
    return(
    <>
        <div className='w-[100%] bg-white flex justify-center py-10 px-5'>
            <div className='w-[100%] pc-3:w-[1366px] grid justify-items-center'>
                <h3 className='px-5 lg:px-0 text-[#000] text-center text-[30px] font-customExtraBold'>{t('fifth.home1')}&nbsp;<span className='text-[#e77b30]'>KTA Kilat</span>&nbsp;{t('fifth.home2')}</h3>
                <p className='font-customRegular text-[12px] text-center'>{t('fifth.text')}</p>
                
                <div className='grid tablet-1:grid-cols-2 justify-items-center pc-1:flex gap-5 mx-5 mt-10'>{
                    items.map((i) =>{
                        return(
                            <div style={{
                                borderRadius: '15px',
                                backgroundSize: '100% 100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${require('../../../assets/images/' + `${i.url}`)})`
                                }} className='w-[300px] tablet-1:w-[100%] min-h-[250px]'>
                                <div className='flex items-end text-white backdrop-brightness-50 rounded-xl w-[100%] h-[100%]'>
                                    <div className='p-5'>
                                        <h3 className='text-[16px] font-customBold'>{i.name}</h3>

                                        <div className='h-full'>
                                            <span className='text-[9px] font-customRegular'>{i.job}</span>
                                            <span className='text-[20px] text-yellow-300 mb-3'>{i.star}</span>
                                            <p className='text-[12px] font-customRegular mt-3'>{i.desc}</p>
                                            <span className='text-[9px] font-customRegular mt-3'>{i.date}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }</div>
            </div>
        </div>
        
        <div className='bg-[#f6f6f6]'>
            <h3 className='mb-5 text-[#000] text-center text-[30px] font-customExtraBold pt-10'>{t('sixth.home')}</h3>
            <div className='w-[100%] flex justify-center pb-10'>
                <div className="w-[100%] pc-3:w-[1366px] inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
                    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                        <li>
                            <img src={require('../../../assets/images/bca.png')} alt="bca" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/mandiri.png')} alt="Mandiri" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/bni.png')} alt="bni" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/bri.png')} alt="bri" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/cimb.png')} alt="cimb" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/permata.png')} alt="permata" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/alfamart.png')} alt="alfamart" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/indomaret.png')} alt="indomaret" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/doku.png')} alt="doku" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/instamoney.png')} alt="instamoney" />
                        </li>
                    </ul>
                    <ul class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                        <li>
                            <img src={require('../../../assets/images/bca.png')} alt="bca" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/mandiri.png')} alt="Mandiri" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/bni.png')} alt="bni" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/bri.png')} alt="bri" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/cimb.png')} alt="cimb" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/permata.png')} alt="permata" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/alfamart.png')} alt="alfamart" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/indomaret.png')} alt="indomaret" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/doku.png')} alt="doku" />
                        </li>
                        <li>
                            <img src={require('../../../assets/images/instamoney.png')} alt="instamoney" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
    )
}