import { useState } from 'react'
import Tab1 from './tab1/Tab-1'
import Tab2 from './tab2/Tab-2'
import Tab3 from './tab3/Tab-3'
import {GoSearch} from 'react-icons/go'
import './Faq.css'
import Poster1 from '../footer/Poster-1'
import { useTranslation } from 'react-i18next'

export default() =>{
    const {t,i18n} = useTranslation()
    const [actived, setActived] = useState(1)
    const toggle = (index) =>{
        setActived(index)
    }
    return(
        <>
        <div className='w-[100%] flex justify-center'>
            <div className='w-[100%] pc-3:w-[1366px]'>

            <div className='grid md:flex w-[100%] h-fit items-end justify-between pt-[90px] px-5 md:px-10 lg:px-14'>
            <div className='flex flex-nowrap'>
                <button className={`px-3 py-3 ${ actived === 1 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(1)
                    }}>{t('first.faq.loan.title')}</button>
                    
                <button className={`px-3 py-3 ${ actived === 2 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(2)
                    }}>{t('second.faq.return.title')}</button>
                <button className={`px-3 py-3 ${ actived === 3 ? 'tabs active' : 'tabs'}`}
                onClick={() =>{
                    toggle(3)
                    }}>{t('third.faq.general.title')}</button>
            </div>

        {/* Search */}
        <div className='hidden md:hidden w-[30%] px-5 pt-5 text-gray-500'>
            <a href='#' role='button' className='flex w-[100%] h-[50px] items-center justify-between px-5 bg-white border border-solid border-gray-300 rounded-xl font-customRegular'>
                Cari <GoSearch/>
            </a>
        </div>
        </div>
        
        {/* Search */}
        <div className='hidden md:hidden w-[100%] px-5 pt-5 text-gray-500'>
            <a href='#' role='button' className='flex w-[100%] h-[50px] items-center justify-between px-5 bg-white border border-solid border-gray-300 rounded-xl font-customRegular'>
                Cari <GoSearch/>
            </a>
        </div>

        <div className='w-[100%] h-[100%]'>
            <div className={`w-[100%] h-fit ${ actived === 1 ? 'contents active' : 'contents'}`}>
                <Tab1/>
            </div>

            <div className={`w-[100%] h-fit ${ actived === 2 ? 'contents active' : 'contents'}`}>
                <Tab2/>
            </div>

            <div className={`w-[100%] h-fit ${ actived === 3 ? 'contents active' : 'contents'}`}>
                <Tab3/>
            </div>
        </div>
            </div>

        </div>

        <Poster1/>
        </>
    )
}