import React, { useState } from 'react'
import './Tab-1.css'
import Pdf1 from '../../../assets/documents/laporan_tahunan_2022.pdf'
import Pdf2 from '../../../assets/documents/laporan_tahunan_2021.pdf'
import Pdf3 from '../../../assets/documents/laporan_tahunan_2020.pdf'
import Pdf4 from '../../../assets/documents/laporan_tahunan_2023.pdf'
import { useTranslation } from 'react-i18next'

export default () =>{
    const {t,i18n} = useTranslation()

    const [actived, setActived] = useState(1)
    const toggle = (index) =>{
        setActived(index)
    }

    const thirdSectionItems = [{
            'url':'frame-5.png',
            'title':'97%',
            'desc':'TKB 90',
            'delay': '200'
        },{
            'url':'frame-5.png',
            'title':'97%',
            'desc':'TKB 90',
            'delay': '200'
        },
        {
            'url':'frame-6.png',
            'title':`${t('third.about.pendanaan.title.operational1')}`,
            'desc':`${t('third.about.pendanaan.text.operational1')}`,
            'delay': '400'
        },
        {
            'url':'frame-7.png',
            'title':`${t('third.about.pendanaan.title.operational2')}`,
            'desc':`${t('third.about.pendanaan.text.operational2')}`,
            'delay': '600'
        },
        {
            'url':'frame-8.png',
            'title':`${t('third.about.pendanaan.title.operational3')}`,
            'desc':`${t('third.about.pendanaan.text.operational3')}`,
            'delay': '800'
        },
        {
            'url':'frame-9.png',
            'title':`${t('third.about.pendanaan.title.operational4')}`,
            'desc':`${t('third.about.pendanaan.text.operational4')}`,
            'delay': '800'
        },
        {
            'url':'frame-10.png',
            'title':`${t('third.about.pendanaan.title.operational5')}`,
            'desc':`${t('third.about.pendanaan.text.operational5')}`,
            'delay': '1000'
        }
    ]

    const direksi =[{
        'url':'luhur.png',
        'name':'Luhur Budiyarso',
        'title':`${t('forth.about.pendanaan.luhur.job')}`,
        'desc': <div>{t('forth.about.pendanaan.luhur.text1')}
        <br/><br/>{t('forth.about.pendanaan.luhur.text2')}
        <br/><br/>{t('forth.about.pendanaan.luhur.text3')}</div>
    },{
        'url':'dino.png',
        'name':'Dino Martin',
        'title':`${t('forth.about.pendanaan.dino.job')}`,
        'desc': <div>{t('forth.about.pendanaan.dino.text1')}
        <br/><br/>{t('forth.about.pendanaan.dino.text2')}
        <br/><br/>{t('forth.about.pendanaan.dino.text3')}
        <br/><br/>{t('forth.about.pendanaan.dino.text4')}
        <br/><br/>{t('forth.about.pendanaan.dino.text5')}</div>
    }]
    return(
        <>
            {/* 1st section */}
            <div className='grid lg:flex justify-center w-[100%] h-fit py-5 pb-10 px-5 xl:px-14 bg-gradient-to-b from-white via-gray-100 to-gray-200'>
                <div className='w-[100%] pc-3:w-[1366px] lg:flex'>
                    <div className='hidden lg:block w-[50%] pr-10 items-center'>
                        <div>
                            <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5'>{t('first.about.pendanaan.title')}</h3>
                            <p className='text-[#000] text-[14px] font-customRegular'><span className='font-customBold'>PT Pendanaan Teknologi Nusa</span> {t('first.about.pendanaan.text1')}
                            <br/><br/>{t('first.about.pendanaan.text2')}
                            <br/><br/>{t('first.about.pendanaan.text3')}
                            <br/><br/>{t('first.about.pendanaan.text4')}</p>
                        </div>
                    </div>
                    
                    <div className='flex w-[100%] lg:w-[50%] items-center justify-center lg:justify-end lg:pt-10 pb-5 pr-0 lg:pr-4'>
                        <img src={require('../../../assets/images/gedung.jpeg')} className='w-[550px] rounded-2xl'/>
                    </div>

                    <div className='block lg:hidden'>
                        <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5'>{t('first.about.pendanaan.title')}</h3>
                        <p className='text-[#000] text-[14px] font-customRegular'><span className='font-customBold'>PT Pendanaan Teknologi Nusa</span> {t('first.about.pendanaan.text1')}
                        <br/><br/>{t('first.about.pendanaan.text2')}
                        <br/><br/>{t('first.about.pendanaan.text3')}
                        <br/><br/>{t('first.about.pendanaan.text4')}</p>
                    </div>
                </div>
            </div>

            {/* 2nd section */}
            <div className='flex justify-center w-[100%] h-[100%] py-12 px-5 xl:px-16 bg-colorOrange'>
                <div className='grid lg:flex w-[100%] pc-3:w-[1366px]'>
                    <div className='w-[100%] lg:w-[50%] bg-white flex p-5 items-center rounded-3xl shadow-lg'>
                        <img src={require('../../../assets/images/visi.png')} alt='visi' className='w-[100px]'/>
                        <div className='grid ml-5'>
                            <h3 className='text-[#000] text-[23px] font-customExtraBold'>{t('second.about.pendanaan.title.vision')}</h3>
                            <p className='mt-1 text-[#000] text-[14px] font-customRegular'>{t('second.about.pendanaan.text.vision')}</p>
                        </div>
                    </div>

                    <div className='w-[100%] lg:w-[50%] bg-white flex p-5 mt-7 lg:mt-0 ml-0 lg:ml-3 items-center rounded-3xl shadow-lg'>
                        <img src={require('../../../assets/images/misi.png')} alt='misi' className='w-[100px]'/>
                        <div className='grid ml-5'>
                            <h3 className='text-[#000] text-[23px] font-customExtraBold'>{t('second.about.pendanaan.title.mission')}</h3>
                            <p className='mt-1 text-[#000] text-[14px] font-customRegular'>{t('second.about.pendanaan.text.mission')}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* 3rd section */}
            {/* <div className='w-[100%] h-fit bg-gradient-to-b from-white via-gray-100 to-gray-200 py-10 px-4'>
                <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('third.about.pendanaan.title')}</h3>
                <div className='flex flex-wrap h-fit justify-center'>
                        {
                            thirdSectionItems.map((i)=>{
                                return(
                                    <div style={{
                                        backgroundColor: `#ffffff`
                                    }} className='border-gray-100 border-[1px] shadow-lg w-[42%] lg:w-[25%] xl:w-[14%] m-3 py-5 px-3 rounded-2xl transform transition duration-500 hover:scale-110'>
                                        <div className='grid text-center text-colorBlueDark justify-items-center'>
                                            <img src={require(`../../../assets/images/${i.url}`)} className='h-[75px] object-cover mb-5' alt={i.title}/>
                                            <span className='mb-3 text-[14px] font-customBold'>{i.title}</span>
                                        </div>
                                        <div className='w-[100%] h-[2px] bg-colorOrangeDark mb-3'/>
                                        <p className='text-[#000] text-[12px] font-customLight text-center'>{i.desc}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
            </div> */}

            {/* 4th section */}
            <div className='flex justify-center w-[100%] h-fit bg-white py-10'>
                <div className='w-[100%] pc-3:w-[1366px]'>
                    <div className='flex justify-center px-5 xl:px-14'>
                        <button className={`py-2 md:py-5 px-3 w-[50%] rounded-l-lg ${ actived === 1 ? 'tab active' : 'tab'}  font-customRegular`}
                        onClick={() =>{
                            toggle(1)
                        }}>{t('forth.about.pendanaan.title1')}</button>
                        <button className={`py-2 md:py-5 px-3 w-[50%] rounded-r-lg ${ actived === 2 ? 'tab active' : 'tab'}  font-customRegular`}
                        onClick={() =>{
                            toggle(2)
                        }}>{t('forth.about.pendanaan.title2')}</button>
                    </div>

                    {/* Content-1 */}
                    <div className={`w-[100%] px-5 lg:px-10 ${ actived === 1 ? 'content active' : 'content'}`}>
                        <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-5 text-center'>{t('forth.about.pendanaan.title1')}</h3>

                        <div className='grid lg:flex w-[100%] h-fit'>
                            {
                                direksi.map((i) =>{
                                    return(
                                        <div className='w-[100%] my-2 mx-0 lg:mx-3 rounded-2xl border-gray-100 border-[1px] shadow-lg px-5 py-7'>
                                            <div className='flex h-fit items-center'>
                                                <img src={require(`../../../assets/images/${i.url}`)} className='h-[75px] object-cover' alt={i.name}/>
                                                <div className='h-[50px] ml-3'>
                                                    <h3 className='text-[16px] font-customBold'>{i.name}</h3>
                                                    <span className='text-[14px] text-slate-500 font-customRegular'>{i.title}</span>
                                                </div>
                                            </div>
                                            <div className='w-[100%] h-[2px] my-5 bg-colorOrangeDark mb-3'/>
                                            <p className='text-[14px] text-[#000] font-customRegular'>{i.desc}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* Content-2 */}
                    <div className={`w-[100%] px-5 lg:px-10 ${ actived === 2 ? 'content active' : 'content'}`}>
                        <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold pt-7 text-center'>{t('forth.about.pendanaan.title2')}</h3>
                        <div className='flex w-[100%] justify-center'>
                            <img src={require(`../../../assets/images/saham-mobile.png`)} className='block md:hidden w-[70%] mt-10 object-cover' alt='saham'/>
                            <img src={require(`../../../assets/images/saham.png`)} className='hidden md:block w-[750px] object-cover justify-self-center ' alt='saham'/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section-5 */}
            <div className='flex justify-center px-5 lg:px-12 pt-10 pb-12 w-[100%] h-fit bg-colorOrangeDark'>
                <div className='w-[100%] pc-3:w-[1366px]'>
                <h3 className='mb-5 text-white text-[30px] font-customExtraBold text-center'>{t('fifth.about.pendanaan.title')}</h3>

                <a role='button' className={`flex bg-white py-2 px-3 w-[100%] shadow-md rounded-xl`}
                    href={Pdf4} target='_blank' rel='noreferrer'>
                        <div className='flex w-[100%] items-center gap-3 font-customExtraBold text-colorBlueDark'>
                            <img src={require('../../../assets/images/pdf.png')}/>{t('fifth.about.pendanaan.text4')}
                        </div>
                </a>
                
                <a role='button' className={`flex bg-white py-2 px-3 w-[100%] mt-3 shadow-md rounded-xl`}
                    href={Pdf1} target='_blank' rel='noreferrer'>
                        <div className='flex w-[100%] items-center gap-3 font-customExtraBold text-colorBlueDark'>
                            <img src={require('../../../assets/images/pdf.png')}/>{t('fifth.about.pendanaan.text1')}
                        </div>
                </a>
                <a role='button' className={`flex bg-white py-2 px-3 w-[100%] mt-3 shadow-md rounded-xl`}
                    href={Pdf2} target='_blank' rel='noreferrer'>
                        <div className='flex w-[100%] items-center gap-3 font-customExtraBold text-colorBlueDark'>
                            <img src={require('../../../assets/images/pdf.png')}/>{t('fifth.about.pendanaan.text2')}
                        </div>
                </a>
                <a role='button' className={`flex bg-white py-2 px-3 w-[100%] mt-3 shadow-md rounded-xl`}
                    href={Pdf3} target='_blank' rel='noreferrer'>
                        <div className='flex w-[100%] items-center gap-3 font-customExtraBold text-colorBlueDark'>
                            <img src={require('../../../assets/images/pdf.png')}/>{t('fifth.about.pendanaan.text3')}
                        </div>
                </a>
                </div>
            </div>
        </>
    )
}