import './App.css'
import React, { useState } from 'react'
import {Route,Routes,Outlet} from 'react-router-dom'
import Navbar from './pages/navbar/Navbar'
import Home from './pages/home/Home'
import About from './pages/about/About'
import News from './pages/news/News'
import Faq from './pages/faq/Faq'
import LifeAtPendanaan from './pages/lifeatpendanaan/LifeAtPendanaan'
import Footer from './pages/footer/Footer'
import Lender from './pages/lender/Lender'
import ScrollToTop from './pages/ScrollToTop'
import StepContext from './pages/lender/application/Step-Context'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { isCancel } from 'axios'
import { BsChevronDown } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { use } from 'i18next'

function WithNav () {
  const [isShowing, setIsShowing] = useState(false)
  const showFAB = () =>{
      if(window.scrollY >= 80){
        setIsShowing(true);
      }
      else{
        setIsShowing(false);
      }
  }
  window.addEventListener('scroll', showFAB)
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer/>
      <a href="#" className={`${isShowing? 'flex' : 'hidden'} shadow-gray-700 drop-shadow-lg act-btn border border-gray-200 transform delay-150 z-10`}>
        <AiOutlineArrowUp/>
      </a>
    </>
  )
}

function WithNoNav () {
  return (
    <>
      <Outlet />
    </>
  )
}

function App() {
  const [isClick, setIsClick1] = useState(null)
  const {t,i18n} = useTranslation()
  const items = [{
      'question':`${t('first.faq.loan.question1.title')}`,
      'answer':<div>{t('first.faq.loan.question1.text1')}</div>,
      'h':65,
      'mb':'5px',
      'c':true
  },{
    'question':`${t('first.faq.loan.question1.title')}`,
    'answer':<div>{t('first.faq.loan.question1.text1')}</div>,
    'h':365,
    'mb':'5px',
    'c':true
}]
  return (
    <>
    <ScrollToTop />
      <Routes>
        <Route element={<WithNav />}>
          <Route path='/' element={<Home/>}/>
          <Route path='/about-us' element={<About/>}/>
          <Route path='/news' element={<News/>}/>
          <Route path='/faq' element={<Faq/>}/>
          <Route path='/life-at-pendanaan' element={<LifeAtPendanaan/>}/>
          <Route path='/lender' element={<Lender/>}/>
        </Route>

        <Route element={<WithNoNav />}>
          <Route path='/lender-app' element={<StepContext/>}/>
        </Route>
      </Routes>
    </>
  )
}

export default App
