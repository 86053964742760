import React, { useRef, useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight } from 'react-icons/fa'
import './Second.css'
import 'swiper/css'
import { useTranslation } from 'react-i18next'
import apk from '../../../assets/documents/KTAKilat.apk'
import axios from "axios";
import i18next from 'i18next'

export default ()=>{
    useEffect(() => {
        getMonthltData()
    }, [])
    const [monthlyData, setmonthlyData] = useState([])
    const [accumulatedBorrowersAll, setAccumulatedBorrowersAll] = useState(false)
    const [accumulatedBorrowers, setAccumulatedBorrowers] = useState('69')
    const [accumulatedLendersAll, setAccumulatedLendersAll] = useState(false)
    const [accumulatedLenders, setaccumulatedLenders] = useState('3')
    const currentYear = new Date().getFullYear()

    function addDot(val, length) {
        if (!val) return 0
        const num = String(Number(val) / length)
        const reg = /\B(?=(\d{3})+(?!\d))/g
        return num.replace(reg, ",")
    }
    
    async function getMonthltData() {
        let monthlyArray = ['9,729', '52', '116', '3,395', '82', '3'] // default
        try{
            await axios.post('https://gateway.pendanaan.com/kta/api/v1/ojk/listOjkRecord', {}, {
                headers: {
                    'tenant': "KTAID",
                    'Content-Type': "application/json",
                    "Accept-Language": i18next.language
                }
            }).then((response) => {
                if(response.data.success){
                    const objData = response.data.data[0]
                    monthlyArray = [
                        addDot(objData.totalAmount, 1e9),
                        addDot(objData.yearTotalAmount, 1e9),
                        addDot(objData.reimbursementTotalAmount, 1e9),
                        addDot(objData.totalBorrowersCurrentYear, 1e3), // 当年借款人数
                        addDot(objData.activeNumber, 1e3), // 活跃用户数
                        objData.totalLendersCurrentYear // 当年资方数量
                    ]
                    setmonthlyData(monthlyArray)
                    setAccumulatedBorrowers(addDot(objData.accumulatedBorrowers, 1e3)) // 累计借款人数
                    setaccumulatedLenders(objData.accumulatedLenders) // 累计资方数
                }
            })
        }catch(e){
            console.log("error: " + e)
            return;
        }
    }

    function setActive(tab) {
        if (tab === 'borrowers') {
            setAccumulatedBorrowersAll(!accumulatedBorrowersAll)
        } else {
            setAccumulatedLendersAll(!accumulatedLendersAll)
        }
    }

    function showData(i, n) {
        if (i.tab === 'borrowers' && accumulatedBorrowersAll) {
            return accumulatedBorrowers
        } else if (i.tab === 'lenders' && accumulatedLendersAll) {
            return accumulatedLenders
        } else {
            return monthlyData[n]
        }
    }

    const {t,i18n} = useTranslation()
    const swiperRef = useRef()
    const swiperRef2 = useRef()
    const values = String
    SwiperCore.use([Autoplay, Pagination])
    const items = [{
            'url':'frame-26.png',
            'title':`${t('second.first.title')}`,
            'desc':`${t('second.first.text')}`,
            'color': '#e77B30',
            'delay': '400',
            'mr': '15px',
            'ml': '15px',
            'mr2': '0px'
        },
        {
            'url':'frame-27.png',
            'title':`${t('second.second.title')}`,
            'desc':`${t('second.second.text')}`,
            'color': '#e77B30',
            'delay': '600',
            'mr': '15px',
            'ml': '15px',
            'mr2': '0px'
        },
        {
            'url':'frame-28.png',
            'title':`${t('second.third.title')}`,
            'desc':`${t('second.third.text')}`,
            'color': '#e77B30',
            'delay': '800',
            'mr': '15px',
            'ml': '15px',
            'mr2': '0px'
        },
        {
            'url':'frame-29.png',
            'title':`${t('second.forth.title')}`,
            'desc':`${t('second.forth.text')}`,
            'color': '#e77B30',
            'delay': '800',
            'mr': '15px',
            'ml': '15px',
            'mr2': '0px'
        },
        {
            'url':'frame-30.png',
            'title':`${t('second.fifth.title')}`,
            'desc':`${t('second.fifth.text')}`,
            'color': '#e77B30',
            'delay': '800',
            'mr': '15px',
            'ml': '15px',
            'mr2': '0px'
        },
        {
            'url':'frame-31.png',
            'title':`${t('second.sixth.title')}`,
            'desc':`${t('second.sixth.text')}`,
            'color': '#e77B30',
            'delay': '800',
            'mr': '25px',
            'ml': '15px',
            'mr2': '15px'
        }
    ]

    const thirdSectionItems = [
        {
            'url':'frame-6.png',
            'title':`${t('third.about.pendanaan.title.operational1')}`,
            'desc':`${t('third.about.pendanaan.text.operational1')}`,
            'delay': '400'
        },
        {
            'url':'frame-7.png',
            'title':`${t('third.about.pendanaan.title.operational2')}`,
            'desc':`${t('third.about.pendanaan.text.operational2')}`,
            'delay': '600'
        },
        {
            'url':'frame-8.png',
            'title':`${t('third.about.pendanaan.title.operational3')}`,
            'desc':`${t('third.about.pendanaan.text.operational3')}`,
            'delay': '800'
        },
        {
            'tab':'borrowers',
            'url':'frame-9.png',
            'title':`${t('third.about.pendanaan.title.operational4')}`,
            'desc':`${t('third.about.pendanaan.text.operational4')}`,
            'delay': '800'
        },
        {
            'url':'frame-10.png',
            'title':`${t('third.about.pendanaan.title.operational5')}`,
            'desc':`${t('third.about.pendanaan.text.operational5')}`,
            'delay': '1000'
        },
        {
            'tab':'lenders',
            'url':'frame-37.png',
            'title':``,
            'desc':`${t('third.about.pendanaan.title.operational6')}`,
            'delay': '600'
        }
    ]
    return(
    <>
        <div className='block lg:flex w-[100%] py-14 bg-[#f6f6f6] lg:justify-center'>
            <div className='hidden lg:flex w-[100%] pc-3:w-[1366px]'>
                <div className='flex w-[20%] items-center justify-center'>
                        <div className='ml-14'>
                            <h3 className='text-[#000] text-[30px] font-customExtraBold'>{t('second.home')}<br/><span className='text-[#e77b30]'>KTA Kilat</span>?</h3>
                            
                            <div className='flex max-w-fit pc-2:w-[225px] justify-between pc-2:gap-3'>
                                <button className='text-[23px] text-[#e77b30] z-20' onClick={() => swiperRef.current?.slidePrev()}><FaRegArrowAltCircleLeft/></button>
                                <span className='swiper-pagination-nolimit flex justify-center mt-[20px]'/>
                                <button className='text-[23px] text-[#e77b30] z-20' onClick={() => swiperRef.current?.slideNext()}><FaRegArrowAltCircleRight/></button>
                            </div>
                        </div>
                </div>
                <div className='flex w-[75%] overflow-hidden [mask-image:_linear-gradient(to_right,_black_128px,_black_calc(100%-200px),transparent_100%,_black_100%)]'>
                    <div className='flex w-[100%] items-center'>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={'auto'}
                        freeMode={false}
                        loop={true}
                        modules={[Pagination]}
                        pagination={{
                            el: '.swiper-pagination-nolimit',
                            clickable: true,
                            type: 'bullets',
                            renderBullet: function () {
                                return(
                                    `<span class="swiper-pagination-bullet"></span>`
                                    )
                                }}}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        className='w-[100%]'>
                        {
                            items.map((i)=>{
                                return(
                                    <SwiperSlide>
                                    <div style={{
                                        backgroundColor: `${i.color}`,
                                        marginRight: `${i.mr}`
                                    }}
                                    className='container'>
                                        <div className='flex justify-center'>
                                            <h3 className='text-[14px] text-center text-white font-customBold mb-3'>{i.title}</h3>
                                        </div>
                                        <div className='flex justify-center'>
                                            <img src={require(`../../../assets/images/${i.url}`)}/>
                                        </div>
                                        
                                        <p className='text-[14px] text-center text-white font-customRegular'>{i.desc}</p>
                                    </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className='grid lg:hidden'>
                <div className='flex w-[100%] h-[50px] items-center mb-10'>
                    <div className='w-[50%] mx-5'>
                        <h3 className='text-[#000] text-[20px] font-customExtraBold'>{t('second.home')}<br/><span className='text-[#e77b30]'>KTA Kilat</span>?</h3>
                    </div>
                    <div className='w-[50%] mr-7'>
                        <div className='flex justify-between gap-3'>
                            <button className='text-[23px] text-[#e77b30] z-20' onClick={() => swiperRef2.current?.slidePrev()}><FaRegArrowAltCircleLeft/></button>
                            <span className='swiper-pagination-nolimit2 flex justify-center mt-[20px]'/>
                            <button className='text-[23px] text-[#e77b30] z-20' onClick={() => swiperRef2.current?.slideNext()}><FaRegArrowAltCircleRight/></button>
                        </div>
                    </div>
                </div>

                <div className='min-w-full'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={'auto'}
                    freeMode={false}
                    modules={[Pagination]}
                    pagination={{
                        el: '.swiper-pagination-nolimit2',
                        clickable: true,
                        type: 'bullets',
                        renderBullet: function () {
                            return(
                                `<span class="swiper-pagination-bullet"></span>`
                                )
                            }}}
                    onBeforeInit={(swiper) => {
                        swiperRef2.current = swiper;
                    }}>
                    {
                        items.map((i)=>{
                            return(
                                <SwiperSlide>
                                <div style={{
                                    backgroundColor: `${i.color}`,
                                    marginRight: `${i.mr2}`,
                                    marginLeft: `${i.ml}`
                                }}
                                className='container'>
                                    <div className='flex justify-center'>
                                        <h3 className='text-[14px] text-center text-white font-customBold mb-3'>{i.title}</h3>
                                    </div>
                                    <div className='flex justify-center'>
                                        <img src={require(`../../../assets/images/${i.url}`)}/>
                                    </div>
                                    
                                    <p className='text-[14px] text-center text-white font-customRegular'>{i.desc}</p>
                                </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    </Swiper>
                </div>
                {/* <div className='w-screen'>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={'auto'}
                    freeMode={false}
                    modules={[Pagination]}
                    pagination={{
                        el: '.swiper-pagination-nolimit2',
                        clickable: true,
                        type: 'bullets',
                        renderBullet: function () {
                            return(
                                `<span class="swiper-pagination-bullet"></span>`
                                )
                            }}}
                    onBeforeInit={(swiper) => {
                        swiperRef2.current = swiper;
                    }}>
                    {
                        items.map((i)=>{
                            return(
                                <SwiperSlide>
                                <div style={{
                                    backgroundColor: `${i.color}`,
                                    marginRight: `${i.mr2}`,
                                    marginLeft: `${i.ml}`
                                }}
                                className='container'>
                                    <div className='flex justify-center'>
                                        <h3 className='text-[14px] text-center text-white font-customBold mb-3'>{i.title}</h3>
                                    </div>
                                    <div className='flex justify-center'>
                                        <img src={require(`../../../assets/images/${i.url}`)}/>
                                    </div>
                                    
                                    <p className='text-[12px] text-center text-white font-customRegular'>{i.desc}</p>
                                </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    </Swiper>
                </div> */}
            </div>
        </div>

        <div className='flex bg-white justify-center items-center'>
            <div className='w-[100%] pc-3:w-[1366px] py-12'>
                <h3 className='mb-5 text-[#000] text-[30px] font-customExtraBold text-center'>{t('third.about.pendanaan.title')}</h3>

                <div className='flex flex-wrap h-fit justify-center'>
                    {
                        thirdSectionItems.map((i, n)=>{
                            return(
                                <div style={{
                                    backgroundColor: `#ffffff`
                                }} className='border-[#e77B30] border-[1px] shadow-lg w-[42%] lg:w-[25%] xl:w-[14%] m-3 py-5 px-3 rounded-2xl'>
                                    <div className='grid text-center text-colorBlueDark justify-items-center'>
                                        <img src={require(`../../../assets/images/${i.url}`)} className='h-[100px] object-cover mb-5' alt={i.title}/>
                                        <div className='flex text-center mb-3 font-customBold gap-1 items-end'>
                                            <span className='text-[25px] -mb-1'>
                                                {/* { showData(i, n) } {i.title} */}
                                                {/* { showData(i, n) } */}
                                                { showData(i, n) }
                                            </span>

                                            <span className='text-[16px]'>
                                                {i.title.substring(i.title.indexOf(' ')) }
                                            </span>
                                        </div>
                                    </div>
                                    <div className='w-[100%] h-[2px] bg-colorOrangeDark mb-3'/>
                                    <p className='text-[#000] text-[14px] font-customLight text-center'>{i.desc}</p>
                                    {
                                        i.tab && <div className='w-[100%] flex justify-center bg-[#f6f6f6] text-[10px] mt-2 rounded-2xl text-center justify-around cursor-pointer'>
                                            <p
                                                className={ `${!((i.tab === 'borrowers' && accumulatedBorrowersAll) || (i.tab === 'lenders' && accumulatedLendersAll)) ? 'bg-colorOrangeDark text-white rounded-2xl' : ''} w-[50%] py-1` }
                                                onClick={()=>{setActive(i.tab)}}
                                                
                                            >{ currentYear }</p>
                                            <p
                                                className={ `${((i.tab === 'borrowers' && accumulatedBorrowersAll) || (i.tab === 'lenders' && accumulatedLendersAll)) ? 'bg-colorOrangeDark text-white rounded-2xl' : ''} w-[50%] py-1` }
                                                onClick={()=>{setActive(i.tab)}}
                                            >{t('since-establish')}</p>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </>
    )
}