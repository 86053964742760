import { FaArrowRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

export default () =>{
    const {t,i18n} = useTranslation()

    const items = [{
            'url':'frame-5.png',
            'title':`${t('third.title.first')}`,
            'desc':`${t('third.text.first')}`,
            'delay': '400'
        },
        {
            'url':'frame-6.png',
            'title':`${t('third.title.second')}`,
            'desc':`${t('third.text.second')}`,
            'delay': '600'
        },
        {
            'url':'frame-7.png',
            'title':`${t('third.title.third')}`,
            'desc':`${t('third.text.third')}`,
            'delay': '800'
        },
        {
            'url':'frame-8.png',
            'title':`${t('third.title.forth')}`,
            'desc':`${t('third.text.forth')}`,
            'delay': '1000'
        },
        {
            'url':'frame-9.png',
            'title':`${t('third.title.fifth')}`,
            'desc':`${t('third.text.fifth')}`,
            'delay': '1200'
        },
        {
            'url':'frame-10.png',
            'title':`${t('third.title.sixth')}`,
            'desc':`${t('third.text.sixth')}`,
            'delay': '1400'
        }
    ] 
    return(
        <div className='grid sm:flex h-fit pb-10 bg-white justify-center'>
            <div className='block sm:hidden'>
                <img src={require('../../../assets/images/ktakilat-logo.png')} className='w-[125px] pc-1:w-[190px] mx-5'/>
                <h3 className='mb-10 mx-5 text-[#000] text-[30px] font-customExtraBold'>{t('third.home')}</h3>
            </div>
            <div className='grid sm:flex h-fit xl:h-[100%] px-5 lg:px-10 justify-items-end pc-3:w-[1366px]'>
                <div className='flex w-[100%] lg:w-[50%] items-center justify-center'>
                    <img src={require('../../../assets/images/frame-4.png')} className='w-[550px]'/>
                    </div>
                    <div className='w-[100%] lg:w-[50%] mt-10 lg:mt-0'>
                        <img src={require('../../../assets/images/ktakilat-logo.png')} className='hidden sm:block w-[125px]'/>
                        <h3 className='hidden sm:block mb-5 text-[#000] text-[30px] font-customExtraBold'>{t('third.home')}</h3>
                        <p className='text-custom-mobile-text pc-1:text-custom-pc-text font-customLight'>{t('third.text1')}
                        <br/><br/>{t('third.text2')}
                        <br/><br/>{t('third.text3')}</p>

                        <CustomLink to = '/about-us' className='flex w-[100%] bg-colorOrangeDark hover:bg-[#ff9346] hover:opacity-90 active:bg-[#ffae74] text-white font-customBold text-[14px] py-3 rounded-md items-center justify-center mt-5'>{t('third.button')} &nbsp;<FaArrowRight/></CustomLink>
                    </div>
                </div>
            </div>
    )
}

function CustomLink({ to, children, ...props }){
    const resolvePath = useResolvedPath(to)
    const isActive = useMatch({path: resolvePath.pathname, end: true})
    return(
        <li className = {`${isActive ? 'active' : ''} list-none`}>
            <Link to = {to} {...props}>{children}</Link>
        </li>
    )
}